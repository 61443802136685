

@font-face {
    font-family: "Source Serif Pro";
    src: url("/fonts/source_serif_pro/SourceSerif4-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Source Serif Pro";
    src: url("/fonts/source_serif_pro/SourceSerif4-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("/fonts/source_sans_pro/SourceSans3-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("/fonts/source_sans_pro/SourceSans3-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Tesla";
    src: url("/fonts/Tesla.otf") format("opentype");
    font-style: normal;
}
