@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 16px;
}

html,
body {
    scroll-behavior: smooth;
}

body.no-overflow {
    overflow: hidden;
}

div,
a,
button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img,
video {
    max-width: none;
    height: auto;
    
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#slider > div > div img {
    box-shadow: 3px 2px 9px 0px rgba(0, 0, 0, 0.25), 13px 9px 16px 0px rgba(0, 0, 0, 0.21),
        29px 20px 21px 0px rgba(0, 0, 0, 0.13), 52px 36px 25px 0px rgba(0, 0, 0, 0.04),
        81px 56px 28px 0px rgba(0, 0, 0, 0);
}

/* 
#CybotCookiebotDialogBodyContentTitle,
#CybotCookiebotDialogBodyContentText,
#CybotCookiebotDialogBodyLevelButtonDescription,
.CybotCookiebotDialogNavItemLink,
.CybotCookiebotDialogBodyButton,
.CookieCard,
.CybotCookiebotScrollArea,
.CybotCookiebotDialogBodyLevelButtonDescription {
    font-family: var(--font-siemens), ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important;
}

.CybotCookiebotDialogDetailBulkConsentCount {
    background-color: #164b45 !important;
}

.CybotCookiebotDialogBodyButton {
    border-radius: 25px !important;
    border: none !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background: #164b45 !important;
}

#CybotCookiebotDialogNav,
#CybotCookiebotDialogHeader,
#CybotCookiebotDialog,
#CybotCookiebotDialogBodyLevelButtonsSelectPane,
.CookieCard {
    border-bottom: 1px solid #e8edec !important;
}

.CybotCookiebotDialogBodyLevelButtonWrapper:not(:last-child) {
    border-right: 1px solid #e8edec !important;
}

.CybotCookiebotDialogActive {
    border-bottom: 2px solid #164b45 !important;
}

.CybotCookiebotDialogNavItemLink {
    color: #164b45 !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialogBodyButtonDecline,
.CybotCookiebotDialogContentWrapper h2,
#CybotCookiebotDialogBodyContentText,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes label,
#CybotCookiebotDialogDetailBodyContentTextAbout,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes a,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes p,
.CybotCookiebotDialogDetailBodyContentCookieInfo,
.CybotCookiebotDialogDetailBodyContentCookieTypeIntro,
#CybotCookiebotDialogBodyFieldsetInnerContainer,
.CybotCookiebotDialogDetailBodyContentCookieInfoTitle,
.CybotCookiebotDialogDetailBodyContentCookieInfoDescription,
.CybotCookiebotDialogDetailBodyContentCookieInfoFooterContent,
.CybotCookiebotDialogDetailBodyContentCookieInfoFooterContent {
    color: #164b45 !important;
}

.CybotCookiebotDialogDetailBulkConsentCount {
    color: white !important;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes button:hover,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes a:hover,
#CybotCookiebotDialogBodyFieldsetInnerContainer span:hover {
    opacity: 0.85 !important;
    color: #164b45 !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialogBodyButtonDecline,
.CybotCookiebotDialogDetailBodyContentCookieGroup {
    border: 1px solid #e8edec !important;
}

.CybotCookiebotDialogDetailBodyContentCookieInfoFooter {
    border-top: 2px solid #164b45 !important;
}

#CybotCookiebotDialogBodyLevelButtonPreferencesInline {
    background-color: #e8edec !important;
}

#CybotCookiebotDialogBodyLevelButtonPreferencesInline:not(:checked) {
    background-color: #e8edec !important;
}

#CybotCookiebotDialogBodyLevelButtonPreferencesInline:checked {
    background-color: #164b45 !important;
}

#CybotCookiebotDialogBodyContentText,
.CybotCookiebotDialogDetailBodyContentCookieTypeIntro,
#CybotCookiebotDialogDetailBodyContentTextAbout,
.CybotCookiebotDialogDetailBodyContentCookieInfoDescription {
    font-family: var(--font-mabry), ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important;
} */
